import { PERCENTAGE, AMOUNT, Accelerator } from '../../../constants';

const cleanSpaces = val => (typeof val !== 'undefined' ? val.trim() : val);

const preMappingProcess = {
  // Owner Mapping
  firstName: values => cleanSpaces(values.owner.firstName),
  lastName: values => cleanSpaces(values.owner.lastName),
  age: values => values.owner.age,
  gender: values => values.owner.gender,
  isOwner: values => values.owner.owner,
  isAnnuitant: values => values.owner.owner,
  isCoveredPerson: values => values.owner.coveredPerson,
  // Second Person
  secondFirstName: values => cleanSpaces(values.secondPerson.firstName),
  secondLastName: values => cleanSpaces(values.secondPerson.lastName),
  secondAge: values => values.secondPerson.age,
  secondGender: values => values.secondPerson.gender,
  isCoOwner: values => values.secondPerson.jointOwner,
  isCoAnnuitant: values => values.secondPerson.jointOwner,
  isSecondCoveredPerson: values => values.secondPerson.coveredPerson,
  // Others
  partialWithdrawals: values =>
    values.hasWithdrawals
      ? values.partialWithdrawals.map(withdrawal => {
          if (values.withdrawalsValueType === PERCENTAGE) {
            withdrawal.amount = undefined;
          } else if (values.withdrawalsValueType === AMOUNT) {
            withdrawal.percentage = undefined;
          }
          return withdrawal;
        })
      : [],
  incomeBenefitStartAge: values => values.incomeBenefit.startAge,
  secondIncomeBenefitStartAge: values => values.incomeBenefit.secondStartAge,
  rmdLifeType: values => values.rmdLifeType,
  /* eslint-disable no-unused-vars */
  requiredMinDistributionOption: values =>
    values.requiredMinDistributionOption && values.product === Accelerator,
  /* eslint-enable no-unused-vars */
  minimumDistributionStartAge: values => {
    if (
      values.requiredMinDistributionOption &&
      values.rmdLifeType === 'Joint'
    ) {
      return values?.isSecondPerson
        ? undefined
        : values?.minimumDistributionStartAge;
    }
    return undefined;
  },
  enhacedIncomeStartAge: values =>
    values.enhacedIncome.startAge ? values.enhacedIncome.startAge : undefined,
  preparedBy: values => cleanSpaces(values.preparedBy),
  entityName: values => cleanSpaces(values.entityName),
  email: values => cleanSpaces(values.email),
  firmName: values => cleanSpaces(values.firmName),
  enhacedIncomeOption: values =>
    values.isEnhancedIncomeState && values.enhacedIncomeOption
};

export default preMappingProcess;
