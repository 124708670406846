import React, { useEffect, useState } from 'react';
import { validateQueryStringToken } from './api/SettingService';
import { useAuth } from './context/authContext';
import { startValues } from './screens/Home/mappers';
import { cleanQuerystring, paramsToObject, tryLoadFonts } from './utils/utils';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

function EmptyContent() {
  return <div />;
}

function renderAppContent(appIsReady, fontsReady, user) {
  if (appIsReady === false || fontsReady === false) {
    return <EmptyContent />;
  }

  if (user && appIsReady === true) {
    return <AuthenticatedApp />;
  }

  if (!user) {
    return <UnauthenticatedApp />;
  }
}

function prepopulateIllustrator() {
  const params = window.location.search;
  if (params && params.includes('input=')) {
    const paramsFiltered = cleanQuerystring(params);
    const {
      state,
      premiumAllocation,
      incomeBenefitStartAge,
      product,
      age,
      gender,
      firstName,
      lastName,
      strategyName_0: strategyName0,
      strategyName_1: strategyName1,
      index_0: index0,
      index_1: index1,
      partialWithdrawals,
      percentage_0: percentage0,
      percentage_1: percentage1,
      preparedBy,
      email,
      authToken,
      jointLife,
      secondCurrentAge,
      isCustomeIllustrationPrepopulationAction
    } = paramsToObject(new URLSearchParams(paramsFiltered).entries());
    if (!authToken) {
      return;
    }
    validateQueryStringToken({ token: authToken }).then(data => {
      if (!data.isValidToken) {
        return;
      }
      if (state) {
        startValues.issueState = state;
      }
      if (premiumAllocation) {
        startValues.premiumAllocation = +premiumAllocation;
      }
      if (product) {
        startValues.product = product;
      }
      if (incomeBenefitStartAge) {
        startValues.incomeBenefit.startAge = +incomeBenefitStartAge;
      }
      if (firstName) {
        startValues.owner.firstName = firstName;
      }
      if (lastName) {
        startValues.owner.lastName = lastName;
      }
      if (age) {
        startValues.owner.age = +age;
      }
      if (gender) {
        startValues.owner.gender = gender;
      }
      if (strategyName0 && index0 && percentage0) {
        startValues.strategies[0] = {
          strategyName: strategyName0 || undefined,
          index: index0 || undefined,
          premiumAllocation: premiumAllocation ? +premiumAllocation : 0,
          percentage: percentage0 ? +percentage0 : 100
        };
      }
      if (strategyName1 && index1 && percentage1) {
        startValues.strategies[1] = {
          strategyName: strategyName1 || undefined,
          index: index1 || undefined,
          premiumAllocation: premiumAllocation ? +premiumAllocation : 0,
          percentage: percentage1 ? +percentage1 : 100
        };
      }

      if (partialWithdrawals) {
        startValues.partialWithdrawals = JSON.parse(partialWithdrawals);
        startValues.hasWithdrawals = true;
      }
      if (preparedBy) {
        startValues.preparedBy = preparedBy;
      }
      if (email) {
        startValues.email = email;
      }
      const jointLifeVal = jointLife?.toLowerCase() === 'true';
      if (jointLifeVal) {
        startValues.isSecondPerson = jointLifeVal;
        startValues.secondPerson.jointOwner = true;
        startValues.secondPerson.coveredPerson = true;
      }
      if (secondCurrentAge) {
        startValues.secondPerson.age = +secondCurrentAge;
      }
      const isCustomIllustration =
        isCustomeIllustrationPrepopulationAction?.toLowerCase() === 'true';
      if (isCustomIllustration) {
        startValues.isCustomeIllustrationPrepopulationAction = isCustomIllustration;
      }
    });
    window.history.pushState({}, document.title, '/');
  }
}

function App() {
  const { user, appIsReady } = useAuth();
  const [fontsReady, setFontsReady] = useState(false);
  useEffect(() => {
    loadAuthenticatedApp();
    tryLoadFonts().then(() => {
      setFontsReady(true);
    });
    prepopulateIllustrator();
  }, []);

  return (
    <React.Suspense fallback={<EmptyContent />}>
      {renderAppContent(appIsReady, fontsReady, user)}
    </React.Suspense>
  );
}

export default App;
