import { doRequest } from './ApiBase';

export const getSettings = async () => {
  try {
    const response = await doRequest({
      url: `/api/Settings`,
      method: 'GET'
    });
    return response;
  } catch (err) {
    console.error('Error trying to get settings', err);
    throw new Error('Unable to get settings, please reload page and try again');
  }
};

export const validateQueryStringToken = async data => {
  try {
    const response = await doRequest({
      url: `/api/Settings/ValidateToken`,
      method: 'POST',
      data
    });
    return response;
  } catch (err) {
    console.error('Error trying to validate token', err);
    throw new Error(
      'Unable to validate token, please reload page and try again'
    );
  }
};
