import _ from 'lodash';
import {
  OWNER_TYPE_ENTITY,
  ILLUSTRATION_METHOD_VARIABLE_ASSUMED,
  PERCENTAGE,
  AMOUNT
} from '../../../constants';

const getBooleanOrDefault = (value, defaultValue) =>
  typeof value === 'boolean' ? value : defaultValue;

const strategiesMapping = (currentStrategies, defaultValue) => {
  if (currentStrategies.length === 1) return currentStrategies;

  let isSameStrategies = true;
  let { strategyName } = currentStrategies[0];

  for (let i = 1; i < currentStrategies.length; i += 1) {
    if (strategyName !== currentStrategies[i].strategyName) {
      isSameStrategies = false;
      break;
    }
    strategyName = currentStrategies[i].strategyName;
  }

  if (isSameStrategies) return currentStrategies;

  return defaultValue.strategies;
};

const mapDataValuesToState = (initialValues, values, settings) => {
  const deepClonedObject = _.cloneDeep(values);
  const state = settings.approvedStates.find(
    ({ value }) => value === deepClonedObject.issueState
  );

  // Case
  deepClonedObject.issueState = state
    ? deepClonedObject.issueState
    : initialValues.issueState;

  deepClonedObject.product = deepClonedObject.product || initialValues.product;

  deepClonedObject.strategies = strategiesMapping(
    deepClonedObject.strategies,
    initialValues
  );

  // Profile
  deepClonedObject.entityName =
    deepClonedObject.ownerType === OWNER_TYPE_ENTITY
      ? deepClonedObject.entityName
      : initialValues.entityName;
  deepClonedObject.owner = {
    firstName: deepClonedObject.firstName || initialValues.owner.firstName,
    lastName: deepClonedObject.lastName || initialValues.owner.lastName,
    age: deepClonedObject.age
      ? parseInt(deepClonedObject.age)
      : initialValues.owner.age,
    gender: deepClonedObject.gender || initialValues.owner.gender,
    owner: getBooleanOrDefault(
      deepClonedObject.isOwner,
      initialValues.owner.isOwner
    ),
    annuitant: getBooleanOrDefault(
      deepClonedObject.isAnnuitant,
      initialValues.owner.isAnnuitant
    ),
    coveredPerson: getBooleanOrDefault(
      deepClonedObject.isCoveredPerson,
      initialValues.owner.coveredPerson
    )
  };
  deepClonedObject.secondPerson = {
    firstName:
      deepClonedObject.secondFirstName || initialValues.secondPerson.firstName,
    lastName:
      deepClonedObject.secondLastName || initialValues.secondPerson.lastName,
    age: deepClonedObject.secondAge
      ? parseInt(deepClonedObject.secondAge)
      : initialValues.secondPerson.age,
    gender: deepClonedObject.secondGender || initialValues.secondPerson.gender,
    jointOwner:
      deepClonedObject.isCoOwner || initialValues.secondPerson.jointOwner,
    coAnnuitant:
      deepClonedObject.isCoAnnuitant || initialValues.secondPerson.coAnnuitant,
    coveredPerson:
      deepClonedObject.isSecondCoveredPerson ||
      initialValues.secondPerson.coveredPerson
  };
  // Income
  deepClonedObject.incomeBenefit = {
    startAge:
      deepClonedObject.incomeBenefitStartAge ||
      initialValues.incomeBenefit.startAge,
    secondStartAge:
      deepClonedObject.secondIncomeBenefitStartAge ||
      initialValues.incomeBenefit.secondStartAge
  };
  deepClonedObject.partialWithdrawals = !deepClonedObject.hasWithdrawals
    ? []
    : deepClonedObject.withdrawals.map(withdrawal => {
        if (values.withdrawalsValueType === PERCENTAGE) {
          withdrawal.amount = undefined;
        } else if (values.withdrawalsValueType === AMOUNT) {
          withdrawal.percentage = undefined;
        }
        return withdrawal;
      });

  deepClonedObject.minimumDistributionStartAge =
    deepClonedObject.requiredMinDistributionOption &&
    deepClonedObject.minimumDistributionStartAge
      ? deepClonedObject.minimumDistributionStartAge
      : initialValues.minimumDistributionStartAge;

  deepClonedObject.rmdLifeType = deepClonedObject.requiredMinDistributionOption
    ? deepClonedObject.rmdLifeType
    : initialValues.rmdLifeType;

  deepClonedObject.requiredMinDistributionOption = false;

  deepClonedObject.enhacedIncome = {
    startAge: deepClonedObject.enhacedIncomeOption
      ? deepClonedObject.enhacedIncomeStartAge
      : initialValues.enhacedIncome.startAge,
    secondStartAge:
      deepClonedObject.enhacedIncomeOption &&
      deepClonedObject.enhacedIncomeStartAge &&
      deepClonedObject.secondIncomeBenefitStartAge
        ? deepClonedObject.enhacedIncomeStartAge -
          deepClonedObject.incomeBenefit.startAge +
          deepClonedObject.secondIncomeBenefitStartAge
        : initialValues.enhacedIncome.secondStartAge
  };
  // Default values for hidden fields
  // illustrationMethod
  if (!deepClonedObject.additionalIllustMethod) {
    deepClonedObject.illustrationMethod = initialValues.illustrationMethod;
    deepClonedObject.variableAssumedRate = initialValues.variableAssumedRate;
    deepClonedObject.fixedRate = initialValues.fixedRate;
  } else if (
    deepClonedObject.illustrationMethod === ILLUSTRATION_METHOD_VARIABLE_ASSUMED
  ) {
    deepClonedObject.fixedRate = initialValues.fixedRate;
  } else {
    deepClonedObject.variableAssumedRate = initialValues.variableAssumedRate;
  }
  // overrideFixed
  if (!deepClonedObject.overrideFixed) {
    deepClonedObject.rateIllustration = initialValues.rateIllustration;
  }
  // Output
  deepClonedObject.firmName = deepClonedObject.firmName
    ? deepClonedObject.firmName
    : initialValues.firmName;

  deepClonedObject.assumedConstantRate = deepClonedObject.assumedConstantRate
    ? deepClonedObject.assumedConstantRate
    : initialValues.assumedConstantRate;

  // Get only keys existing on initial values
  const result = _.pick(deepClonedObject, _.keys(initialValues));
  // Replace all null values for undefined
  return _.mapValues(result, item => (item == null ? undefined : item));
};

export default mapDataValuesToState;
