import _ from 'lodash';
import FIELDS_MAPPING from './fieldsMapping';
import preMappingFunctions from './preMappingFunctions';

const mapFormValuesToApiPayload = valuesParam => {
  const data = {};
  const values = _.cloneDeep(valuesParam);

  FIELDS_MAPPING.map(field => {
    let value =
      values[field.fieldName] !== 'undefined'
        ? values[field.fieldName]
        : field.defaultValue;

    if (preMappingFunctions[field.fieldName]) {
      value = preMappingFunctions[field.fieldName](values);
    }

    const apiParamName = field.apiParamName || field.fieldName;

    return Object.assign(data, {
      [apiParamName]: value
    });
  });

  return data;
};

export default mapFormValuesToApiPayload;
